import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class JsLoaderService {

  public constructor(@Inject(DOCUMENT) protected document: Document) {}

  public loadJavascript(scriptUrl) {
    return new Promise((resolve, reject) => {
      //check if the script is already loaded
      let scripts = this.document.getElementsByTagName('script');
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src == scriptUrl) {
          resolve(true);
        }
      }
      //if not, load it
      const script = this.document.createElement('script');
      script.src = scriptUrl;
      script.type = 'text/javascript';
      this.document.body.appendChild(script);
      //wait until the script is loaded
      script.onload = () => {
        resolve(true);
      };
    });

  }

}
